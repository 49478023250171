.MainScreenGrid {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0.2rem 0.5rem;
}

.MainScreenGridLeft {
    display: flex;
    grid-gap: 0.2rem;
    justify-content: right;
}

.MainScreenGridRight {
    position: relative;
}

.MainScreenGridRightInput {
    display: flex;
    justify-content: stretch;
    width: 100%;
    gap: 0.2rem;
}

.MainScreenGridRightInput > input[type=text] {
    flex: 1;
}

.MainScreenGridRightInput > input[type=button] {
    background: var(--green-7);
    border-radius: var(--radius-round);
    border: none;
    color: white;
    font-size: 1.25rem;
    font-weight: var(--font-weight-9);
    text-align: center;
    cursor: pointer;
}

.MainScreenGridRightInput > input[type=button]:hover {
    background: var(--green-8);
}

.MainScreenGridRightInput > input[type=button]:active {
    background: var(--green-9);
}

.MainScreenSpinners input {
    width: 4ch;
    -webkit-appearance: none;
    -moz-appearance:textfield; /* Firefox */
}
.MainScreenSpinners input::-webkit-outer-spin-button,
.MainScreenSpinners input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

#inputYear {
    width: 4ch;
}

#inputWeek {
    width: 2ch;
}

.HighLightItem {
    font-weight: bold;
}

.ItemToolbox {
    position: absolute;
    display: none;
    left: 0;
    top: 0;
    background: white;
    transform: translateX(-100%);
    border-radius: var(--radius-4);
}

.MainScreenGridRight:hover > .ItemToolbox {
    display: flex;
    flex-direction: column;
}

.ButtonItem {
    border-radius: var(--radius-round);
    border: none;
    color: white;
    font-size: 1.25rem;
    font-weight: var(--font-weight-9);
    text-align: center;
    cursor: pointer;
    margin: 0.1rem;
}

.ButtonRemoveItem {
    background: var(--red-7);
}
.ButtonRemoveItem:hover {
    background: var(--red-8);
}
.ButtonRemoveItem:active {
    background: var(--red-9);
}

.ButtonEditItem {
    background: var(--violet-7);
}
.ButtonEditItem:hover {
    background: var(--violet-8);
}
.ButtonEditItem:active {
    background: var(--violet-9);
}

.ButtonHighlightItem {
    background: var(--yellow-7);
}
.ButtonHighlightItem:hover {
    background: var(--yellow-8);
}
.ButtonHighlightItem:active {
    background: var(--yellow-9);
}
